import { Header } from "@tanstack/react-table"
import { classNames } from "../../utils"

type Props = {
  className?: string
  label: string
  srOnly?: boolean
  header: Header<any, any>
}

const TH = ({ header, label, srOnly, className = "" }: Props) => (
  <th
    scope="col"
    key={header.id}
    colSpan={header.colSpan}
    className={classNames(
      header.index === 0 ? "pl-2 pr-3" : "px-3",
      "text-left text-sm font-medium uppercase py-4",
      className
    )}
  >
    {header.isPlaceholder ? null : (
      <span className={srOnly ? "sr-only" : ""}>{label}</span>
    )}
  </th>
)

export default TH
