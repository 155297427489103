import Button from "../button"

type Props = {
  children: React.ReactNode
  className?: string
  type?: "button" | "submit" | "reset"
  disabled?: boolean
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}

const LightBtn = ({
  children,
  className = "",
  type = "button",
  disabled = false,
  onClick
}: Props) => (
  <Button
    disabled={disabled}
    type={type}
    className={`${className} border-transparent text-primary-alt-400 bg-zinc-100 hover:bg-zinc-200 focus:ring-zinc-300`}
    onClick={onClick}
  >
    {children}
  </Button>
)

export default LightBtn
