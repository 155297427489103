import { LucideProps } from "lucide-react"

const currentClassName = "z-2 bg-primary-50 border-primary-500 text-primary-600"
const defaultClassName =
  "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"

type Option = {
  label: string
  value: string
  Icon?: (props: LucideProps) => JSX.Element
}
type Props = {
  className: string
  value: string
  showLabel?: boolean
  onClick: (newValue: string) => void
  options: Option[]
}

const classNameDefault = ""

const ToggleBtn = ({
  showLabel,
  value,
  onClick,
  className = classNameDefault,
  options
}: Props) => (
  <div className={`flex rounded-md -space-x-px ${className}`}>
    {options.map(({ label, value: optionValue, Icon }, index) => (
      <button
        key={index}
        type="button"
        onClick={() => onClick(optionValue)}
        className={`${
          value === optionValue ? currentClassName : defaultClassName
        } h-9 relative inline-flex items-center px-4 py-2 border text-sm font-medium group ${
          index === 0 ? "rounded-l-md" : "rounded-r-md"
        }`}
      >
        <span className={showLabel ? "" : "sr-only"}>{label}</span>
        {Icon && (
          <Icon
            size={16}
            aria-hidden="true"
            className={
              value === optionValue
                ? "text-base-dark"
                : "group-hover:text-base-dark"
            }
          />
        )}
      </button>
    ))}
  </div>
)

export default ToggleBtn
