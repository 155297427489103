import { InputHTMLAttributes, useState, useEffect } from "react"

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  className = "",
  debounce = 500,
  type = "text",
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
  className?: string
  type?: string
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value, onChange, debounce])

  return (
    <input
      {...props}
      value={value}
      type={type}
      onChange={e => setValue(e.target.value)}
      className={`${className} appearance-none block w-full px-3 py-2 border border-neutral-200 rounded-md placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm`}
    />
  )
}

export default DebouncedInput
