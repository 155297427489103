import { Field } from "formik"

type Props = {
  label: string
  subLabel?: string
  name: string
  className?: string
}

const classNameDefault = ""

const Checkbox = ({
  label,
  subLabel,
  name,
  className = classNameDefault
}: Props) => (
  <div className={`relative flex items-start ${className}`}>
    <div className="flex items-center h-5">
      <Field
        id={name}
        name={name}
        type="checkbox"
        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
      />
    </div>
    <div className="ml-3 text-sm">
      <label htmlFor={name} className="font-medium text-gray-700">
        {label}
      </label>
      {subLabel && <p className="text-gray-500">{subLabel}</p>}
    </div>
  </div>
)

export default Checkbox
