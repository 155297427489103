import LinkBtn from "../link-btn"

type Props = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void
  className?: string
  children: React.ReactNode
}

const LinkBtnDanger = ({ children, onClick, className = "" }: Props) => (
  <LinkBtn onClick={onClick} className={className} theme="danger">
    {children}
  </LinkBtn>
)

export default LinkBtnDanger
