import Button from "../button"

type Props = {
  children: React.ReactNode
  className?: string
  type?: "button" | "submit" | "reset"
  disabled?: boolean
  theme?: "primary" | "danger" | "alt"
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}

const LinkBtn = ({
  theme = "primary",
  children,
  className = "",
  type = "button",
  disabled = false,
  onClick
}: Props) => {
  const themeKey =
    theme === "primary" ? "primary" : theme === "danger" ? "red" : "primary-alt"

  const themeClassName = `text-${themeKey}-400 hover:text-${themeKey}-500 focus:ring-${themeKey}-300`

  return (
    <Button
      disabled={disabled}
      type={type}
      className={`${themeClassName} ${className} border-transparent bg-transparent hover:underline`}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}
export default LinkBtn
