import Form from "../../../form"

type Props = {
  globalFilter: string
  searchPlaceholder?: string
  onSearch: (value: string | number) => void
  children?: React.ReactNode
  className?: string
}

const classNameDefault = ""

const SearchBar = ({
  globalFilter,
  onSearch,
  searchPlaceholder,
  children,
  className = classNameDefault
}: Props) => (
  <div
    className={`${className} lg:flex items-center bg-white pb-4 lg:-mt-[54px] sm:-mt-[108px] -mt-[118px]`}
  >
    <Form.SearchInput
      wrapperClassName="w-full lg:w-auto mb-3 lg:mb-0"
      placeholder={searchPlaceholder ?? ""}
      value={globalFilter ?? ""}
      onSearch={onSearch}
      className="pl-10 lg:min-w-[300px]"
    />
    <div className="flex items-center w-full">{children}</div>
  </div>
)

export default SearchBar
