import { LayoutList, LayoutGrid } from "lucide-react"
import ToggleBtn from "../../../toggle-btn"

type Options = "table" | "grid"

type Props = {
  className: string
  viewType: Options
  setViewType: (viewType: Options) => void
}

const classNameDefault = ""

const ToggleView = ({
  viewType,
  setViewType,
  className = classNameDefault
}: Props) => (
  <ToggleBtn
    className={className}
    value={viewType}
    onClick={setViewType as (newValue: string) => void}
    options={[
      { label: "Table", value: "table", Icon: LayoutList },
      { label: "Grid", value: "grid", Icon: LayoutGrid }
    ]}
  />
)

export default ToggleView
