import { useMemo } from "react"

export const DOTS = "…"

const range = (start: number, end: number) => {
  let length = end - start + 1
  /*
  	Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start)
}

type Props = {
  pageIndex: number
  siblingCount: number
  totalCount: number
}

export const usePagination = ({
  siblingCount = 3,
  pageIndex,
  totalCount
}: Props) => {
  const paginationRange = useMemo(() => {
    // Pages count is determined as siblingCount + firstPage + lastPage + pageIndex + 2*DOTS
    const totalPageNumbers = siblingCount + 5

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalCount]
    */
    if (totalPageNumbers >= totalCount) {
      return range(1, totalCount)
    }

    /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalCount
    */
    const leftSiblingIndex = Math.max(pageIndex - siblingCount, 1)
    const rightSiblingIndex = Math.min(pageIndex + siblingCount, totalCount)

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2
    const shouldShowRightDots = rightSiblingIndex < totalCount - 2

    const firstPageIndex = 1
    const lastPageIndex = totalCount

    /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount
      let leftRange = range(1, leftItemCount)

      return [...leftRange, DOTS, totalCount]
    }

    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount
      let rightRange = range(totalCount - rightItemCount + 1, totalCount)
      return [firstPageIndex, DOTS, ...rightRange]
    }

    /*
    	Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex)
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
    }
  }, [siblingCount, pageIndex, totalCount])
  return paginationRange || []
}
