import Button from "../button"

type Props = {
  children: React.ReactNode
  className?: string
  type?: "button" | "submit" | "reset"
  disabled?: boolean
  onClick: (event: React.MouseEvent<HTMLElement>) => void
  refProp?: React.Ref<HTMLButtonElement>
}

const PrimaryBtn = ({
  children,
  className = "",
  type = "button",
  disabled = false,
  refProp,
  onClick
}: Props) => (
  <Button
    refProp={refProp}
    disabled={disabled}
    type={type}
    className={`${className} border-transparent text-white bg-primary-400 hover:bg-primary-500 focus:ring-primary-300 disabled:bg-neutral-400`}
    onClick={onClick}
  >
    {children}
  </Button>
)

export default PrimaryBtn
